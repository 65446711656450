<template>
  <div>
    <div class="das_top_cont flex_center" style="height:35.714285714285715rem;font-size:2.142857142857143rem">
    This page is currently under development
    </div>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";

export default {
  mixins: [mixins1],
  data() {
    return {

    };
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},

  methods: {

}
}
</script>
<style scoped>


</style>